.auth-template-wrapper {
  background-image: url('../../assets/svg/auth-background.svg');
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-color: rgba(164, 177, 153, 1);
  display: flex;
  justify-content: center;
}
.auth-template-content {
  padding-top: 40px;
  padding-bottom: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 440px;
}
.auth-template-form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  border-radius: 14px;
  margin-top: 84px;
  padding: 40px 52px;
}

.auth-template-wrapper button {
  cursor: pointer;
}

.auth-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 335px;
  gap: 10px;
}
.auth-form .auth-title {
  margin-bottom: 30px;
  color: var(--Bl, #000);
  text-align: center;
  font-family: Cormorant Garamond;
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.auth-form button {
  border-radius: 10px;
  border-width: 0;
  background: var(--Ol, #556447);
  color: var(--White, #fff);
  text-align: center;
  font-family: Roboto;
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: 0.84px;
  text-transform: uppercase;
  display: flex;
  width: 335px;
  height: 52px;
  padding: 6px 20px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.auth-form .reset-info {
  text-align: center;
  margin-bottom: 30px;
  margin-top: -10px;
  font-size: 16px;
  line-height: 22.4px;
}

.auth-form .MuiInput-root.MuiInput-variantOutlined {
  width: 335px;
  padding: 0 10px;
  border: 1px solid #dbe0d6 !important;
  border-radius: 10px;
}
.auth-form .MuiInput-root.MuiInput-variantOutlined::before {
  box-shadow: none !important;
}

.auth-form .MuiInput-root.MuiInput-variantOutlined.MuiInput-colorNeutral.Mui-focused {
  border: 1px solid #dbe0d6 !important;
}

.auth-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 10px;
}
.auth-footer h4 {
  color: var(--White, #fff);
  text-align: center;
  font-family: Barlow Semi Condensed;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}
.auth-footer button {
  display: inline-flex;
  height: 30px;
  padding: 0px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid var(--White, #fff);
  background-color: transparent;
  color: var(--White, #fff);
  text-align: center;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: 0.52px;
  text-transform: uppercase;
}
